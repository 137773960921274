/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import {useStaticQuery, graphql} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

const Bio = () => {
    const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
              twitter, 
              github
          }
        }
      }
    }
  `)

    // Set these values by editing "siteMetadata" in gatsby-config.js
    const author = data.site.siteMetadata?.author
    const social = data.site.siteMetadata?.social

    function getSocialLink(key) {
        switch(key) {
            case 'github':
                return (<li key={key}><a href={'https://github.com/' + social[key]}>github</a></li>);
            case 'twitter':
                return (<li key={key}><a href={'https://twitter.com/' + social[key]}>twitter</a></li>);
            default:
                return '';
        }
    }

    return (
        <div className="bio">
            <StaticImage
                className="bio-avatar"
                layout="fixed"
                formats={["AUTO", "WEBP", "AVIF"]}
                src="../images/profile-pic.png"
                width={50}
                height={50}
                quality={95}
                alt="Profile picture"
            />
            <p>
                <strong>{author.name}</strong><br/>
                {author?.summary}
                {Object.keys(social).map((key) => {
                    return getSocialLink(key)
                })}
            </p>
        </div>
    )
}

export default Bio
